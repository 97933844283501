<template>
  <CyModal
    v-if="environment"
    :header-title="$t('removeEnvironmentHeadline')"
    :loading="loading"
    :action-btn-func="deleteEnvironment"
    :cancel-btn-func="cancel"
    :width="554"
    modal-type="delete">
    <p class="delete-sentence">
      {{ $t('sentence1') }}
    </p>

    <p class="delete-sentence mb-6">
      {{ $t('sentence2') }}
    </p>

    <div class="d-flex align-center">
      <p class="ma-0 mr-4">
        {{ $t('sentence3') }}
      </p>
      <span class="d-flex align-center font-weight-bold">
        <CyAvatar
          :item="environment"
          class="mr-1"
          sm/>
        {{ environment.canonical }}
      </span>
    </div>
  </CyModal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CyFormsEnvironmentDelete',
  props: {
    environment: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions('organization/project', [
      'DELETE_ENV',
    ]),
    async deleteEnvironment () {
      this.loading = true
      await this.DELETE_ENV({ envCanonical: this.environment.canonical })
      this.$emit('success')
      this.loading = false
    },
    cancel () {
      this.$emit('cancel')
    },
  },
  i18n: {
    messages: {
      en: {
        removeEnvironmentHeadline: 'Delete environment',
        sentence1: 'Deleting an environment will not delete resources that have been deployed by the pipeline.',
        sentence2: 'Please remove them via the pipeline job or manually as they may generate costs.',
        sentence3: 'Do you still want to delete this environment?',
      },
      es: {
        removeEnvironmentHeadline: 'Borrar entorno',
        sentence1: 'Borrar un entorno no borrará los recursos desplegados por la pipeline.',
        sentence2: 'Por favor, bórrelos, usando la tarea de la pipeline o manualmente, ya que pueden generar costes.',
        sentence3: '¿Realmente quiere borrar este entorno?',
      },
      fr: {
        removeEnvironmentHeadline: `Supprimer l'environnment`,
        sentence1: `La suppression d'un environnement ne supprimera pas les ressources déployées par la pipeline.`,
        sentence2: 'Supprimez-les via la pipeline ou manuellement, car elles peuvent générer des coûts supplémentaires.',
        sentence3: 'Voulez-vous toujours supprimer cet environnement ?',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-sentence {
  margin: 0;
  font-weight: bold;
}
</style>

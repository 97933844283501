<template>
  <v-data-table
    v-bind="$attrs"
    class="environments-list elevation-0 overflow-hidden"
    :items="environments"
    :headers="$static.headers"
    :search="search"
    hide-default-footer
    disable-pagination
    disable-sort
    @click:row="goToEnv">
    <template #progress>
      <v-progress-linear
        v-if="$attrs.loading"
        indeterminate
        color="secondary"
        class="environments-list__loader"/>
    </template>
    <template #top>
      <div
        v-has-rights-to="['UpdateProject', projectCanonical]"
        class="environments-list__header elevation-0">
        <v-text-field
          v-model="search"
          :placeholder="$t('forms.fieldSearch')"
          hide-details
          clearable
          class="search-field ma-0 pa-0"
          data-cy="search-field"
          prepend-icon="search"/>
        <CyButton
          icon="add"
          data-cy="add-env-button"
          :disabled="_.isEmpty(configRepository)"
          :to="{ name: 'addServiceEnv', params: { mode: 'add-env' } }">
          {{ $t('environment.create') }}
        </CyButton>
      </div>
    </template>
    <template #[`item.canonical`]="{ item }">
      <td class="d-flex align-center">
        <CyAvatar
          :item="item"
          sm/>
        <span class="ml-2 font-weight-bold">
          {{ item.canonical }}
        </span>
      </td>
    </template>
    <template #[`item.provider`]="{ item }">
      <div
        v-if="item.cloudProviderCanonical"
        class="d-flex">
        <CyIconCredential
          class="mr-1"
          :type="item.cloudProviderCanonical"
          size="24"/>
        <span>
          {{ item.cloudProviderName }}
        </span>
      </div>
    </template>
    <template #[`item.useCase`]="{ item }">
      <v-icon
        v-if="item.useCase"
        class="usecase__icon ml-2"
        size="15">
        fa-cube
      </v-icon>
      <span
        v-if="item.useCase"
        class="usecase__text ml-1">
        {{ item.useCase }}
      </span>
    </template>
    <template #[`item.pipelineStatus`]="{ item }">
      <div
        v-if="item.pipelineStatus"
        class="pipeline-status d-flex align-center">
        <v-icon :class="['pipeline-status__icon', `${item.pipelineStatus}-color`]">
          {{ pipelineIcon(item.pipelineStatus) }}
        </v-icon>
        <span class="ml-2">
          {{ _.capitalize(item.pipelineStatus) }}
        </span>
      </div>
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="d-flex justify-end space-x-2">
        <template v-if="item.startStop">
          <CyTooltip bottom>
            <template #activator="{ on }">
              <CyButton
                v-has-rights-to="['CreateBuild', projectCanonical]"
                :loading="item.startStop.starting"
                data-cy="play-button"
                icon="play_arrow"
                icon-only
                sm
                theme="primary"
                variant="tertiary"
                light
                v-on="on"
                @click.prevent.stop="$emit('start', item)"/>
            </template>
            {{ $t('startStopTooltipText', { action: $t('pipeline.start') }) }}
          </CyTooltip>
          <CyTooltip bottom>
            <template #activator="{ on }">
              <CyButton
                v-has-rights-to="['CreateBuild', projectCanonical]"
                :loading="item.startStop.stopping"
                data-cy="stop-button"
                icon="stop"
                icon-only
                sm
                theme="primary"
                variant="tertiary"
                light
                v-on="on"
                @click.prevent.stop="$emit('stop', item)"/>
            </template>
            {{ $t('startStopTooltipText', { action: $t('pipeline.stop') }) }}
          </CyTooltip>
        </template>
        <CyMenu
          v-if="!_.isEmpty(getEnvActions(item))"
          data-cy="actions-menu"
          offset-y
          left
          sm
          min-width="190"
          max-width="300">
          <v-list>
            <v-subheader>
              <div class="v-subheader__title cy-headline">
                {{ $t('Environment') }}
              </div>
              <div class="v-subheader__environment">
                <CyAvatar
                  :item="item"
                  sm/>
                <span class="canonical">
                  {{ item.canonical }}
                </span>
              </div>
            </v-subheader>
            <template v-for="({ action, label, icon, color, divider, disabled, readOnly }, index) in getEnvActions(item)">
              <v-divider
                v-if="divider"
                :key="`menu-divider-${index}`"/>
              <v-list-item
                v-else
                :key="`menu-item-${index}`"
                v-hover-siblings
                :disabled="disabled"
                @click="action">
                <v-list-item-content>
                  <v-list-item-title
                    class="d-flex align-center"
                    :style="{ color: color }">
                    <v-icon
                      class="mr-2"
                      :disabled="disabled"
                      :color="color || 'primary'"
                      size="24">
                      {{ icon }}
                    </v-icon>
                    <span :class="{ 'red--text': color === 'error' }">
                      {{ label }}
                    </span>
                    <span v-if="readOnly">
                      <CyTooltip
                        :max-width="240"
                        right>
                        <template #activator="{ on: onTooltip }">
                          <div v-on="onTooltip">
                            <CyTag
                              variant="default"
                              small
                              class="action-item__readonly-chip ml-6 mr-1">
                              {{ $t('readOnly') }}
                            </CyTag>
                          </div>
                        </template>
                        <span v-html="$sanitizeHtml($t('readOnlyTooltipText'))"/>
                      </CyTooltip>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </CyMenu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'CyEnvironmentListTable',
  props: {
    environments: {
      type: Array,
      default: () => [],
    },
    configRepository: {
      type: Object,
      default: () => {},
    },
    projectCanonical: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    $static () {
      return {
        headers: [
          {
            text: this.$t('Environment'),
            value: 'canonical',
            class: 'd-flex align-center',
          },
          {
            text: this.$t('provider'),
            value: 'provider',
          },
          {
            text: this.$t('environment.useCase'),
            value: 'useCase',
          },
          {
            text: this.$t('pipelineStatus'),
            value: 'pipelineStatus',
          },
          {
            value: 'actions',
            align: 'end',
          },
        ],
      }
    },
  },
  methods: {
    getEnvActions (env) {
      const disabled = _.isEmpty(this.configRepository)
      const { canDisplay } = this.$cycloid.permissions
      return [
        {
          key: 'settings',
          icon: 'construction',
          label: this.$t('Settings'),
          disabled,
          action: () => {
            this.$router.push({ name: 'projectConfigurationEnvironment', params: { envCanonical: env.canonical } })
          },
          permissionKey: 'UpdateProject',
        },
        {
          key: 'configuration',
          icon: 'tune',
          label: this.$t('configuration'),
          disabled,
          action: () => {
            this.$router.push({ name: 'environmentConfig', params: { envCanonical: env.canonical } })
          },
          permissionKey: 'GetProject',
          readOnly: !canDisplay('UpdateProject', this.projectCanonical),
        },
        {
          divider: true,
          permissionKey: 'UpdateProject',
        },
        {
          key: 'clone',
          icon: 'content_copy',
          label: `${this.$t('clone')}...`,
          disabled,
          action: () => {
            this.$router.push({ name: 'environmentClone', params: { envCanonical: env.canonical } })
          },
          permissionKey: 'UpdateProject',
        },
        {
          divider: true,
          permissionKey: 'UpdateProject',
        },
        {
          key: 'delete',
          icon: 'delete',
          label: this.$t('deleteEnv'),
          action: () => (this.$emit('delete', env)),
          color: 'error',
          permissionKey: 'UpdateProject',
        },

      ].filter(({ permissionKey }) => canDisplay(permissionKey, this.projectCanonical))
    },
    pipelineIcon (pipelineStatus) {
      const icons = {
        failed: 'error',
        paused: 'pause_circle_filled',
        errored: 'warning',
        aborted: 'cancel',
        pending: 'pending',
        running: 'fas fa-circle-notch fa-spin',
        succeeded: 'check_circle',
      }

      return icons[pipelineStatus] || 'pending'
    },
    goToEnv (env) {
      this.$router.push({ name: 'environment', params: { envCanonical: env.canonical } })
    },
  },
  i18n: {
    messages: {
      en: {
        deleteEnv: 'Delete @:environment...',
        pipelineStatus: '@:Pipeline status',
        readOnly: 'Read only',
        readOnlyTooltipText: '@:youDoNotHavePermissionsTo configure this environment',
        startStopTooltipText: '{action} scheduled environment manually',
      },
      es: {
        deleteEnv: 'Eliminar @:environment...',
        pipelineStatus: 'Estado de la @.lower:Pipeline',
        readOnly: 'Solo lectura',
        readOnlyTooltipText: '@:youDoNotHavePermissionsTo configurar este entorno',
        startStopTooltipText: '{action} el entorno programado manualmente',
      },
      fr: {
        deleteEnv: `Supprimer l'@:environment...`,
        pipelineStatus: 'Statut de la @.lower:Pipeline',
        readOnly: 'Lecture seule',
        readOnlyTooltipText: '@:youDoNotHavePermissionsTo configurer cet environnement',
        startStopTooltipText: `{action} l'environnement planifié manuellement`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table {
    &__wrapper {
      @extend %cy-scrollbars-always-show;

      max-height: calc(95vh - 270px - 24px); // screen height minus header and its margins
      overflow: auto;
    }

    &__wrapper > table > tbody > tr {
      cursor: pointer;
    }
  }

  .v-input {
    &__prepend-outer {
      margin-left: 12px;
    }

    &__slot {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .v-subheader {
    display: flex;
    justify-content: space-between;

    &__title {
      color: cy-get-color("grey", "dark-2")
    }

    &__environment {
      display: flex;
      align-items: center;
      max-width: 160px;
      margin-left: 16px;
      color: cy-get-color("primary");

      .canonical {
        margin-left: 8px;
        font-size: $font-size-default;
        font-weight: $font-weight-bolder;

        @extend %ellipsis;
      }
    }
  }
}

.environments-list {
  border: solid 1px cy-get-color("grey", "light-1");
  color: cy-get-color("primary");

  &__header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: solid 1px cy-get-color("grey", "light-1");

    .search-field {
      display: flex;
      flex: 0 0 25% !important;
      align-items: center;
      height: $spacer-9;
      border-radius: 2px;
      background: cy-get-color("primary", "light-5");
      color: cy-get-color("primary", "light-3");

      ::v-deep {
        .v-icon {
          color: inherit;
        }

        .v-input__append-inner {
          margin-right: $spacer-2;
        }
      }

      ::placeholder {
        color: cy-get-color("primary", "light-3");
      }
    }
  }

  &__loader {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.v-divider {
  &:not(.v-divider--vertical) {
    &:not(.sibling-of-hovered) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.usecase {
  &__icon {
    color: cy-get-color("primary", "light-3");
  }

  &__text {
    color: cy-get-color("primary", "light-2");
  }
}

.pipeline-status {
  &__icon {
    &.failed-color { color: cy-get-color("build", "failed"); }
    &.succeeded-color { color: cy-get-color("build", "succeeded"); }
    &.errored-color { color: cy-get-color("build", "errored"); }
    &.aborted-color { color: cy-get-color("build", "aborted"); }
    &.pending-color { color: cy-get-color("build", "pending"); }
    &.paused-color { color: cy-get-color("build", "paused"); }
  }
}
</style>

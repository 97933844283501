import { render, staticRenderFns } from "./environments.vue?vue&type=template&id=7ac7a49a&scoped=true"
import script from "./environments.vue?vue&type=script&lang=js"
export * from "./environments.vue?vue&type=script&lang=js"
import style0 from "./environments.vue?vue&type=style&index=0&id=7ac7a49a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac7a49a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCol,VRow,VSlideXReverseTransition})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
